import React from "react";
import { convertToMoney } from "../../utils/common";

export default function RedeemConfirmation({
  selectedRow,
  onConfirmGetCode,
  onClose,
  isLoading,
  loyaltyPoints,
}) {
  return (
    <>
      <div className="reward-modal-wrapper">
        <div className="reward-modal-header">
          <h5>Use Points</h5>
          <p>Balance: {convertToMoney(loyaltyPoints)}</p>
        </div>

        <p>
          You are redeeming a coupon code for <b>{selectedRow?.coupon_value}</b>
          . This coupon code is for one-time use only.{" "}
          <b>{convertToMoney(selectedRow?.reward_points)} points</b> will be
          deducted from your loyalty account.
        </p>

        <div className="reward-modal-action text-right">
          <span className="link-style" onClick={() => onClose()}>
            Cancel
          </span>
          <button
            className="button button-lower"
            onClick={() => onConfirmGetCode()}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
}