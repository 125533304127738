import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";

import IconClose from "../../../assets/icon_js/IconClose";

Modal.setAppElement("body");

const DialogHead = ({ children }) => {
  return <div className="modal-title">{children}</div>;
};

function ReactModal({ isOpen, setIsOpen, title, children }) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <div className="modal-outer">
        <div className="modal-inner">
          <div className="modal-body-outer">
            <button
              type="button"
              className="modal-close-button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <IconClose />
            </button>

            {title !== "" && (
              <div className="modal-header">
                <DialogHead>{title}</DialogHead>
              </div>
            )}

            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ReactModal.propTypes = {
  chidlren: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default ReactModal;
