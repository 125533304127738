import React from "react";
import { getUser } from "../utils/auth";
import Layout from "../layout/Layout";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import SideBar from "../sections/LoyaltyAndRewards/SideBar";
import { useLocation } from "@reach/router";
import LoyaltyAndRewards from "../sections/LoyaltyAndRewards/LoyaltyAndRewardPage";

function LoyaltyPointsPage({ pageContext }) {
  const { activeTab } = pageContext;
  const user = getUser();
  const location = useLocation();
  const pageTitle = formatTitle(activeTab);

  function formatTitle(inputString) {
    const stringWithSpaces = inputString.replace(/-/g, " ");
    const formattedString = stringWithSpaces
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedString;
  }
  return (
    <>
      <div className="page-wrapper page-reward">
        <div className="container">
          <Breadcrumbs location={location} title={pageTitle} />
        </div>

        <div className="container">
          {user ? (
            <div className="row flex flex-wrap page-layout">
              <div className="col page-sidebar reward-app-sidebar">
                <SideBar activeTab={activeTab} />
              </div>
              <div className="col page-content">
                <LoyaltyAndRewards activeTab={activeTab} />
              </div>
            </div>
          ) : (
            <>Please login to access the page</>
          )}
        </div>
      </div>
    </>
  );
}

LoyaltyPointsPage.Layout = Layout
export default LoyaltyPointsPage;