import React, { useEffect, useState } from "react";
import RedeemCoupon from "./RedeemCouponScreen";
import History from "./HistoryScreen";
import MyRewards from "./MyRewardsScreen";
import useGetRewards from "../../hooks/loyaltypoints/useGetRewards";
import { getUser } from "../../utils/auth";

function LoyaltyAndRewards({ activeTab }) {
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const { getUserLoyaltyPoints } = useGetRewards();
  const user = getUser();

  const getUserPoints = async () => {
    const data = await getUserLoyaltyPoints(user);
    if (Object.keys(data).length !== 0) {
      setLoyaltyPoints(data?.LoyaltyPoints);
    }
  };
  useEffect(() => {
    getUserPoints();
  }, []);
  return (
    <div>
      {activeTab === "redeem-coupon" && (
        <RedeemCoupon userpoints={loyaltyPoints} />
      )}
      {activeTab === "my-rewards" && <MyRewards points={loyaltyPoints} />}
      {activeTab === "history" && <History loyaltyPoints={loyaltyPoints} />}
    </div>
  );
}

export default LoyaltyAndRewards;