import Api from '../../services/Api'
import useToastify from '../ui/useToastify'

export default function useGetRewards() {
  const { toastMessage } = useToastify()
  async function getRewardsList(user) {
    const url = user?.customer_email ? `/customers/loyalty/rewards` : null
    const result = getRewards(url, user)
    return result
  }

  async function getCustomerCoupons(user) {
    const url = user?.customer_email
      ? `/customers/loyalty/customer/coupons`
      : null
    const result = getCoupons(url, user)
    return result
  }

  async function generateUserCoupon(payload) {
    const url = `/customers/loyalty/coupon`
    const result = await CreateCoupons(url, payload)
    if (Object.keys(result).length !== 0) {
      toastMessage('success', 'Coupon generated successfully')
    }

    return result
  }

  async function getCustomerHistory(user) {
    const url = user?.customer_email
      ? `/customers/loyalty/customer/history`
      : null
    const result = getHistoryData(url, user)
    return result
  }

  async function getUserLoyaltyPoints(user) {
    const url = user?.customer_email
      ? `/customers/loyalty/customer/loyaltypoints`
      : null
    const result = getLoyaltyPoints(url, user)
    if (Object.keys(result).length !== 0) {
      toastMessage('success', 'Customer not found.')
    }
    return result
  }

  return {
    getRewardsList,
    getCustomerCoupons,
    generateUserCoupon,
    getCustomerHistory,
    getUserLoyaltyPoints,
  }
}

async function getRewards(url, user) {
  const payload = {
    customer_id: user?.customer_id,
  }
  const response = await Api.get(url, payload)
  if (response?.status !== 200) {
    // console.log(response?.response?.data?.error, "responseo0o0o0o");
    return {}
  }
  return response?.data
}

async function getCoupons(url, user) {
  const payload = {
    customer: user?.customer_email,
  }
  const response = await Api.get(url, payload)
  if (response?.status !== 200) {
    // console.log(response?.response?.data?.error, "responseo0o0o0o");
    return {}
  }
  return response?.data
}

async function CreateCoupons(url, payload) {
  const response = await Api.post(url, payload)
  // console.log(response?.response, "in call");
  if (response?.status !== 200) {
    // console.log(response?.response?.data?.error, "responseo0o0o0o");
    return {}
  }
  return response?.data?.data
}

async function getHistoryData(url, user) {
  const payload = {
    customer: user?.customer_email,
  }
  const response = await Api.get(url, payload)
  if (response?.status !== 200) {
    // console.log(response?.response?.data?.error, "responseo0o0o0o");
    return []
  }
  return response?.data
}

async function getLoyaltyPoints(url, user) {
  const payload = {
    customer_id: user?.customer_id,
  }
  const response = await Api.get(url, payload)
  if (response?.status !== 200) {
    // console.log(response?.response?.data?.error, "responseo0o0o0o");
    return {}
  }
  return response?.data
}
