import React, { useState, useEffect } from "react";
import { convertTimeStampToDate } from "../../utils/common";
import useGetRewards from "../../hooks/loyaltypoints/useGetRewards";
import { getUser } from "../../utils/auth";
import { convertToMoney } from "../../utils/common";

function History({ loyaltyPoints }) {
  const user = getUser();
  const { getCustomerHistory } = useGetRewards();

  const [historyData, setHistoryData] = useState([]);
  const getHistory = async () => {
    const data = await getCustomerHistory(user);
    if (data.length) {
      setHistoryData(data);
    }
  };

  useEffect(() => {
    getHistory();
  }, []);

  const convertDescription = (description, flag) => {
    if (flag) {
      const parts = description.split("Earned");
      const pointsString = parts[1].split("Points");
      return (
        <>
          <span className="green">Earned </span>
          <strong>
            {convertToMoney(parseInt(pointsString[0].trim()))} Points
          </strong>
          {pointsString[1]}
        </>
      );
    } else {
      const parts = description.split("Spent");
      const pointsString = parts[1].split("points");
      return (
        <>
          <span className="red">Spent </span>
          <strong>
            {convertToMoney(parseInt(pointsString[0].trim()))} Points
          </strong>
          {pointsString[1]}
        </>
      );
    }
  };

  return (
    <div className="reward-point-screen reward-point-screen_1">
      <div className="page-heading-section">
        <h1 className="page-title">History</h1>
      </div>
      <div className="reward-point-block">
        <p>Balance:</p>
        <h4>{convertToMoney(loyaltyPoints)}</h4>
      </div>

      <div className="reward-table-block">
        <table>
          <thead>
            <tr>
              <th className="text-left">Date</th>
              <th className="text-left">Description</th>
              <th className="text-left">Balance</th>
            </tr>
          </thead>
          <tbody>
            {historyData && historyData?.length ? (
              historyData.map((row) => (
                <tr>
                  <td>
                    <p className="table-date-before"> {convertTimeStampToDate(row["created_at"], true)}</p>
                  </td>
                  <td>
                    <div className="align-items-center table-description-before">
                      {row["earned/used"] < 0
                        ? convertDescription(row["description"], 0)
                        : convertDescription(row["description"], 1)}
                    </div>
                  </td>
                  {/* <td>
                        <p>
                          <strong className="green">Earned</strong>
                          <strong>5000 Points</strong> for Anniversary Bonus
                        </p>
                      </td> */}
                  <td>
                    <p className="table-balance-before">{convertToMoney(row["balance"])}</p>
                  </td>
                </tr>
              ))
            ) : (
              <>
                <br />
                No data found
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default History;