import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import useGetRewards from "../../hooks/loyaltypoints/useGetRewards";
import { getUser } from "../../utils/auth";
import RedeemConfirmation from "./RedeemConfirmModal";
import useToastify from "../../hooks/ui/useToastify";
import ReactModal from "../../components/form/Dialog/NewModal";
// import { convertToMoney } from "../../utils/money";
import { convertToMoney } from "../../utils/common";

function RedeemCoupon({ userpoints }) {
  const { toastMessage } = useToastify();
  const user = getUser();  
  const { getRewardsList, generateUserCoupon } = useGetRewards();

  const [rewards, setRewards] = useState([]);
  const [loyaltyPoints, setLoyaltyPoints] = useState(userpoints);
  const [showCopyCode, setShowCopyCode] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [newCode, setNewCode] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getallRewards = async () => {
    const data = await getRewardsList(user);
    if (Object.keys(data).length !== 0 && data?.rewards.length) {
      setRewards(data?.rewards);
    }
  };
  useEffect(() => {
    getallRewards();
    setShowCopyCode(false);
    setSelectedRow(null);
    setLoyaltyPoints(userpoints);
  }, [userpoints]);

  const onConfirmGetCode = async () => {
    setIsLoading(true);
    const match = selectedRow?.coupon_value.match(/\$[^ ]+/);
    const extractedValue = match ? match[0] : "";
    const payload = {
      name: extractedValue,
      points: parseInt(selectedRow?.reward_points),
      customer: user?.customer_email,
    };
    if (extractedValue !== "") {
      const response = await generateUserCoupon(payload);
      if (Object.keys(response).length !== 0) {
        setShowCopyCode(true);
        setLoyaltyPoints(response?.loyalty_points);
        setNewCode(response?.code);
        setOpen(!open);
        setIsLoading(false);
      } else {
        onCancelGetCode();
        setIsLoading(false);
      }
    }
  };
  const onClickGetCode = (row) => {
    setSelectedRow(row);
    // setShowConfirmation(true);
    setOpen(true);
  };

  const onCancelGetCode = () => {
    setOpen(!open);
    setSelectedRow(null);
    // setShowConfirmation(false);
    setShowCopyCode(false);
  };
  const handleCopyCode = (code) => {
    // Create a temporary input element to copy text to the clipboard
    const tempInput = document.createElement("input");
    tempInput.value = code;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // setShowCopyCode(false);
    // setNewCode("");
    toastMessage("success", "Code copied successfully.");
  };

  return (
    <div className="reward-point-screen reward-point-screen_1">
      <div className="page-heading-section">
        <h1 className="page-title">Redeem Coupons</h1>
      </div>
      {showCopyCode ? (
        <div className="reward-success-section">
          <div className="reward-success-inner">
            <h6 className="">Congratulations!</h6>
            <p>
              Your <strong>{selectedRow?.coupon_value}</strong> coupon
              redemption is a success. Apply below code during the checkout.{" "}
            </p>
            <p>Happy Shopping!</p>
            <div className="code-copy-block">
              <span>
                <button type="button" onClick={() => handleCopyCode(newCode)}>
                  {newCode}
                </button>
              </span>
            </div>
            <p>
              For later usage, the coupon code will be available in{" "}
              <Link href="/loyalty-and-rewards/my-rewards">My Rewards</Link>
            </p>
            <button
              type="button"
              className="button"
              onClick={() => handleCopyCode(newCode)}
            >
              Copy Code
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="reward-point-block">
            <p>Availabel Points:</p>
            <h4>{convertToMoney(loyaltyPoints)}</h4>
          </div>
          <div className="reward-table-block">
            <table>
              <thead>
                <tr>
                  <th className="text-left">Coupon Value</th>
                  <th className="text-center">Reward Points</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {rewards && rewards.length > 0 ? (
                  rewards.map((row) => (
                    <tr>
                      <td>
                        <p className="table-coupon-value-before">{row["coupon_value"]}</p>
                      </td>

                      <td>
                        <p className="text-center table-reward-point-before">
                          {convertToMoney(row["reward_points"])} points
                        </p>
                      </td>
                      <td>
                        <p className="text-right">
                          <button
                            type="button"
                            className="button"
                            onClick={() => onClickGetCode(row)}
                            disabled={!row["is_applicable"]}
                          >
                            Get Coupon
                          </button>
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <br />
                    No data found
                  </>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}

      <ReactModal
        isOpen={open}
        setIsOpen={onCancelGetCode}
        title=""
        class="small-wrapper"
      >
        <RedeemConfirmation
          loyaltyPoints={loyaltyPoints}
          selectedRow={selectedRow}
          onConfirmGetCode={onConfirmGetCode}
          onClose={onCancelGetCode}
          isLoading={isLoading}
        />
      </ReactModal>
    </div>
  );
}

export default RedeemCoupon;