import React, { useState, useEffect } from "react";
import useGetRewards from "../../hooks/loyaltypoints/useGetRewards";
import { getUser } from "../../utils/auth";
import useToastify from "../../hooks/ui/useToastify";
import { convertToMoney } from "../../utils/common";
import { formatDateCst, formatDateNew } from "../../utils/date";

function MyRewards({ points }) {
  const user = getUser();
  const { getCustomerCoupons } = useGetRewards();
  const { toastMessage } = useToastify();
  const [loyaltyPoints, setLoyaltyPoints] = useState(points);
  const [coupons, setCoupons] = useState([]);

  const getallCoupons = async () => {
    const data = await getCustomerCoupons(user);
    if (Object.keys(data).length !== 0) {
      setCoupons(data?.coupons);
      setLoyaltyPoints(data?.loyalty_points);
      // setButtonTexts(new Array(data?.coupons.length).fill("Copy Code"));
    }
  };
  const handleCopyCode = (code) => {
    // Create a temporary input element to copy text to the clipboard
    const tempInput = document.createElement("input");
    tempInput.value = code;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    toastMessage("success", "Code copied successfully.");
  };

  useEffect(() => {
    getallCoupons();
    setLoyaltyPoints(points);
  }, [points]);

  return (
    <div className="reward-point-screen reward-point-screen_1">
      <div className="page-heading-section">
        <h1 className="page-title">My Rewards</h1>
      </div>
      <div className="reward-point-block">
        <p>Balance:</p>
        <h4>{convertToMoney(loyaltyPoints)}</h4>
      </div>

      <div className="reward-table-block">
        <table>
          <thead>
            <tr>
              <th className="text-left">Coupon Value</th>
              <th className="text-left">Coupon Code</th>
              <th className="text-left">Expiry Date </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {coupons && coupons?.length ? (
              coupons.map((row, index) => (
                <tr key={index}>
                  <td>
                    <p className="table-coupon-value-before">
                      {row["name"].split(" ").slice(-1)[0].toLowerCase()} off{" "}
                    </p>
                  </td>
                  <td>
                    <p className="table-coupon-code-before">{row["code"]}</p>
                  </td>
                  <td>
                    <p
                      className={`table-coupon-expiry-before ${
                        row["is_expired"] ? "warning-color" : ""
                      }`}
                    >
                      {formatDateNew(row["expires"], true)}
                    </p>
                  </td>
                  <td>
                    <p className="text-right">
                      <button
                        type="button"
                        className="button btn btn-secondary"
                        onClick={() => handleCopyCode(row["code"])}
                        disabled={!row["enabled"] || row["is_expired"]}
                      >
                        Copy Code
                      </button>
                    </p>
                  </td>
                </tr>
              ))
            ) : (
              <>
                <br />
                No data found
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MyRewards;