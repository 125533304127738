import React from "react";
import IconReward from "../../assets/icon_js/IconReward";
import { Link } from "gatsby";

function SideBar() {
  const menuItems = [
    { path: "redeem-coupon", label: "Redeem Coupon" },
    { path: "my-rewards", label: "My Rewards" },
    { path: "history", label: "History" },
  ];

  return (
    <div className="page-sidebar-inner-wrap">
      <div className="sidebar-block">
        <h5 className="sidebar-heading">
          <i className="icon">
            <IconReward />
          </i>
          Loyalty & Rewards
        </h5>
        <ul className="sidebar-nav-list">
          {menuItems.map((menuItem) => (
            <li key={menuItem.path}>
              <Link to={`/loyalty-and-rewards/${menuItem.path}`}>
                {menuItem.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="sidebar-block">
        <h5 className="sidebar-heading">
          <i className="icon">
            <IconFriend />
          </i>
          Refer a Friend
        </h5>
        <ul className="sidebar-nav-list">
          <li>
            <Link to="/">Menu one</Link>
          </li>
          <li>
            <Link to="/">Menu one</Link>
          </li>
        </ul>
      </div> */}
    </div>
  );
}

export default SideBar;