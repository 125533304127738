import React, { Component } from 'react'
export default class IconReward extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="1024"
        height="1024"
        viewBox="0 0 1024 1024"
      >
        <title></title>
        <g id="icomoon-ignore"></g>
        <path
          fill="none"
          stroke="#888"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="64"
          d="M256 384c0 67.895 26.971 133.009 74.981 181.018 48.009 48.013 113.124 74.982 181.019 74.982s133.009-26.97 181.018-74.982c48.013-48.009 74.982-113.122 74.982-181.018s-26.97-133.010-74.982-181.019c-48.009-48.009-113.122-74.981-181.018-74.981s-133.010 26.971-181.019 74.981c-48.009 48.009-74.981 113.124-74.981 181.019z"
        ></path>
        <path
          fill="none"
          stroke="#888"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="64"
          d="M512 640l145.067 251.307 68.181-137.941 153.515 9.899-145.067-251.264"
        ></path>
        <path
          fill="none"
          stroke="#888"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="64"
          d="M290.233 512l-145.067 251.307 153.515-9.941 68.181 137.899 145.065-251.264"
        ></path>
      </svg>
    )
  }
}
